import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
const AnswerOptionsSelect = ({
  AnswerArray,
  setData, Data,
  DataValue, DataType,
  option,
  ArrayIndex,
  QuestionsSolved, setQuestionsSolved,
  index,
  index2,
  isPlayingAnswer, setIsPlayingAnswer, setIsPlayingMedia,
  mediaPlayed, setMedaiPlayed, oneQuestionData }) => {
  return (
    <div className="answer-option-style"
      // onClick={() => {
      //   setData(option)
      //   if (AnswerArray[ArrayIndex].answerChoosen == "") {
      //     setQuestionsSolved(QuestionsSolved + 1)
      //   }
      //   const ArrayJSONvalue = AnswerArray[ArrayIndex]
      //   AnswerArray[ArrayIndex] = {
      //     question_number: ArrayJSONvalue.question_number,
      //     correct_answer: ArrayJSONvalue.correct_answer,
      //     answerChoosen: option,
      //     solved: 1
      //   }
      // }}
      style={{ display: 'flex', }}
    >
      <div style={{marginRight:"5px",cursor:"pointer"}}
        onClick={() => {
          setData(option)
          if (AnswerArray[ArrayIndex].answerChoosen == "") {
            setQuestionsSolved(QuestionsSolved + 1)
          }
          const ArrayJSONvalue = AnswerArray[ArrayIndex]
          AnswerArray[ArrayIndex] = {
            question_number: ArrayJSONvalue.question_number,
            correct_answer: ArrayJSONvalue.correct_answer,
            answerChoosen: option,
            solved: 1
          }
        }}
      >
        {Data == option ?
          <div style={{
            border: "4px solid #000",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#000"
          }}>
            &nbsp;{index}&nbsp;
          </div>
          :
          <div style={{
            border: "4px solid black",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold"
          }}>
            &nbsp;{index}&nbsp;
          </div>
        }
        {/* {Data == option ?
          <i className="fa fa-check-circle-o" style={{ fontSize: 25, color: "green" }}></i>
          : <i className="fa fa-circle-o" style={{ fontSize: 25 }}></i>
        } */}
      </div>

      <div className={Data == option ? "active-option2" : "passive-option2"}>
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "180px",
                // height: "180px",
                // objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <div style={{cursor:"pointer"}} onClick={() => {
              try {
                if (mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0") {
                  const sound = new Audio(DataValue)
                  sound.play()
                  setIsPlayingAnswer(true)
                  setIsPlayingMedia(true)
                  setMedaiPlayed({
                    ...mediaPlayed,
                    [`answer-${oneQuestionData.question_number}-${index2}`]: "1"
                  })
                  sound.addEventListener('ended', () => {
                    setIsPlayingAnswer(false)
                    setIsPlayingMedia(false)
                  })
                }
              }
              catch {
                console.log("error")
              }
            }}>
              <div style={{ position: "absolute" }}>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                  {isPlayingAnswer ?
                    <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                    :
                    <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                  }
                </div>
              </div>
              <img src="https://ubtvirtualtest.com/wp-content/uploads/2022/09/headpone.png"
                style={{ width: "100px", height: "100px" }}
              />

              {/* <div className="button-play" style={{
                borderColor: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666",
                color: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666"
              }}>
                {isPlayingAnswer ?
                  <i className="fa fa-pause"></i>
                  :
                  <i className="fa fa-play "></i>
                }
              </div> */}
            </div>
          </div>
          // <audio
          //   controls
          //   src={`${DataValue}`}
          // >
          //   {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
          //   {/* <source src={`${DataValue}`} type="audio/mpeg" />
          //   Your browser does not support the audio element. */}
          // </audio>
        }
        {DataType == "video" &&
          <video width="400" controls>
            <source src={`${DataValue}`} type="video/mp4"
              style={{ height: "150px", width: "200px" }}
            />
          </video>
        }
        {DataType == "youtube" &&
          <iframe width="400" height="150" src={`https://www.youtube.com/embed/${DataValue}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        }
      </div>
    </div>
  )
}

export default function ThridScreenComponent(props) {
  const {
    oneQuestionData,
    AnswerArray, ArrayIndex,
    setQuestionsSolved, QuestionsSolved,
    mediaPlayed, setMedaiPlayed, setIsPlayingMedia
  } = props;
  const [question, setQuestion] = React.useState(oneQuestionData.question)

  // const [playMusic, setMusic] = useAudio("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3");

  // const [playQuestionDescription, setQuestionDescriptionPlay] = useAudio(
  //   // oneQuestionData.question_description_type == "audio" ?
  //   //   oneQuestionData.question_description :
  //   "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
  //   , oneQuestionData, mediaPlayed, setMedaiPlayed, setIsPlayingMedia);
  // const [playQuestionMedia, setQuestionMediaPlay] = useAudio(oneQuestionData.question_media_type == "audio" ? oneQuestionData.question_media : "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3", oneQuestionData, mediaPlayed, setMedaiPlayed, setIsPlayingMedia)

  const [AnswerSelected, setAnswerSelected] = React.useState("")
  const [ImageSelect, setImageSelect] = React.useState("")
  useEffect(() => {
    if (AnswerArray[ArrayIndex].answerChoosen !== "") {
      setAnswerSelected(AnswerArray[ArrayIndex].answerChoosen)
    }
  }, [])
  const audioQuestionDesc = React.createRef();
  const [isPlayingQuestionMedia, setIsPlayingQuestionMedia] = React.useState(false);
  const [isPlayingAnswer1, setIsPlayingAnswer1] = React.useState(false);
  const [isPlayingAnswer2, setIsPlayingAnswer2] = React.useState(false);
  const [isPlayingAnswer3, setIsPlayingAnswer3] = React.useState(false);
  const [isPlayingAnswer4, setIsPlayingAnswer4] = React.useState(false);

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div style={{
            margin: "3px", padding: "3px",
            border: "1px solid #999", overflowY: 'scroll'
          }}
            className="question-height"
          >
            <div
              style={{ padding: "10px 0 0" }}
            >
              <h3 style={{ fontSize: 18, fontWeight: "bold" }}>
                {oneQuestionData.question_number}. {oneQuestionData.question}
              </h3>
            </div>

            <div className="question-description col-12">
              {oneQuestionData.question_description_type == "text" &&
                <div style={{ fontSize: "17px", border: "1px solid #000", padding: "0 0 15px" }}>
                  {/* <div className=> */}
                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                  {/* <textarea
                    className="col-11 border-0 p-1 "
                    rows="4" cols="100"
                    value={oneQuestionData.question_description}
                    readOnly
                  /><br /> */}
                </div>
              }
              {oneQuestionData.question_description_type == "image" &&
                <>
                  <div
                    onClick={() => {
                      setImageSelect(oneQuestionData.question_description)
                    }}
                  >
                    <img
                      src={oneQuestionData.question_description}
                      style={{
                        height: "300px",
                        width: "392px",
                        objectFit: 'contain',
                        border: "0.5px solid #000"
                      }}
                    />
                  </div>
                </>
              }
              {oneQuestionData.question_description_type == "audio" &&
                <div>
                  <div style={{cursor:"pointer"}} onClick={() => {
                    try {
                      if (mediaPlayed[`question-${oneQuestionData.question_number}`] == "0") {
                        const sound = new Audio(oneQuestionData.question_description)
                        sound.play()
                        setIsPlayingQuestionMedia(true)
                        setIsPlayingMedia(true)
                        setMedaiPlayed({
                          ...mediaPlayed,
                          [`question-${oneQuestionData.question_number}`]: "1"
                        })
                        sound.addEventListener('ended', () => {
                          setIsPlayingQuestionMedia(false)
                          setIsPlayingMedia(false)
                        })
                      }
                    }
                    catch {
                      console.log("error")
                    }
                  }}>
                    <div style={{ position: "absolute" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                        {isPlayingQuestionMedia ?
                          <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                          :
                          <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                        }
                      </div>
                    </div>
                    <img src="https://ubtvirtualtest.com/wp-content/uploads/2022/09/headpone.png"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  {/* <audio controls ref={audioQuestionDesc}>
                    
                    <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio> */}
                </div>
              }
              {oneQuestionData.question_description_type == "video" &&
                <video width="400" controls>
                  <source src={`${oneQuestionData.question_description}`} type="video/mp4"
                    style={{ height: "150px", width: "200px" }}
                  />
                </video>
              }
              {oneQuestionData.question_description_type == "youtube" &&
                <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_description}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              }
            </div>

            {oneQuestionData.question_media !== "" &&
              <div className="question-description col-10">
                {oneQuestionData.question_media_type == "text" &&
                  `${oneQuestionData.question_media}`
                }

                {oneQuestionData.question_media_type == "image" &&
                  <div
                    onClick={() => {
                      setImageSelect(oneQuestionData.question_media)
                    }}
                  >
                    <img
                      src={oneQuestionData.question_media}
                      style={{
                        height: "300px",
                        width: "392px",
                        objectFit: 'contain',
                        border: "0.5px solid #000"
                      }}
                    />
                  </div>
                }
                {oneQuestionData.question_media_type == "audio" &&
                  <div style={{cursor:"pointer"}} onClick={() => {
                    try {
                      if (mediaPlayed[`question-${oneQuestionData.question_number}`] == "0") {
                        const sound = new Audio(oneQuestionData.question_media)
                        sound.play()
                        setIsPlayingQuestionMedia(true)
                        setIsPlayingMedia(true)
                        setMedaiPlayed({
                          ...mediaPlayed,
                          [`question-${oneQuestionData.question_number}`]: "1"
                        })
                        sound.addEventListener('ended', () => {
                          setIsPlayingQuestionMedia(false)
                          setIsPlayingMedia(false)
                        })
                      }
                    }
                    catch {
                      console.log("error")
                    }
                  }}>
                    <div style={{ position: "absolute" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "100px", height: "100px" }}>
                        {isPlayingQuestionMedia ?
                          <i className="fa fa-pause" style={{ fontSize: "30px" }}></i>
                          :
                          <i className="fa fa-play " style={{ fontSize: "30px" }}></i>
                        }
                      </div>
                    </div>
                    <img src="https://ubtvirtualtest.com/wp-content/uploads/2022/09/headpone.png"
                      style={{ width: "100px", height: "100px" }}
                    />

                  </div>
                }
                {oneQuestionData.question_media_type == "video" &&
                  <video width="400" controls>
                    <source src={`${oneQuestionData.question_media}`} type="video/mp4"
                      style={{ height: "150px", width: "200px" }}
                    />
                  </video>
                }
                {oneQuestionData.question_media_type == "youtube" &&
                  <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_media}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
            }
          </div>
        </div>
        <div className="col-md-4">
          <div style={{  border: "1px solid #999", overflowY: 'scroll' }}
            className="question-height"
          >
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_1}
              DataType={oneQuestionData.answer_media_type}
              option={"option 1"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={1}
              index2={"a"}
              isPlayingAnswer={isPlayingAnswer1}
              setIsPlayingAnswer={setIsPlayingAnswer1}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_2}
              DataType={oneQuestionData.answer_media_type}
              option={"option 2"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={2}
              index2={"b"}
              isPlayingAnswer={isPlayingAnswer2}
              setIsPlayingAnswer={setIsPlayingAnswer2}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_3}
              DataType={oneQuestionData.answer_media_type}
              option={"option 3"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={3}
              index2={"c"}
              isPlayingAnswer={isPlayingAnswer3}
              setIsPlayingAnswer={setIsPlayingAnswer3}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray}
              setData={setAnswerSelected} Data={AnswerSelected}
              DataValue={oneQuestionData.option_4}
              DataType={oneQuestionData.answer_media_type}
              option={"option 4"}
              ArrayIndex={ArrayIndex}
              QuestionsSolved={QuestionsSolved} setQuestionsSolved={setQuestionsSolved}
              index={4}
              index2={"d"}
              isPlayingAnswer={isPlayingAnswer4}
              setIsPlayingAnswer={setIsPlayingAnswer4}
              mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
              oneQuestionData={oneQuestionData} setIsPlayingMedia={setIsPlayingMedia}
            />
          </div>
        </div>
      </div>
      {ImageSelect !== "" &&
        <div className="modal center"
        onClick={()=>{
          setImageSelect("")
        }}
        >
          <img className="image-modal"
            src={ImageSelect}
          />

        </div>
      }
    </div>
  )
}